import { template as template_cf54cb6ebade4242a5a95494f88f3ca3 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_cf54cb6ebade4242a5a95494f88f3ca3(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
