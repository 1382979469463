import { template as template_49f3a8140e5a44278aa33d10d68a70cb } from "@ember/template-compiler";
const FKText = template_49f3a8140e5a44278aa33d10d68a70cb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
